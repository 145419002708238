.PlayerList
{      
    z-index: 999;
    width: 100%;
    top:0;
    position: fixed;
}

.PlayerList > div
{
    /* margin-bottom: 40px; */
    margin-right: 40px;
}

.PlayerList div
{
    /* margin-bottom: 40px; */
    float: left;
}

.PlayerList .points
{
    margin-left: 5px;
    line-height: 26px;
    font-family: 'Courier New';
    font-weight: bold;
    color: white;
}

body
{
    background: rgb(131,58,180);
    /*background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);    */
}

.Board { 
    font-size: 20px;
    position: relative;
    margin: 30px auto; 
    background-color: rgba(255,255,255,0.3);    
    /* background-color: black; */
    /* background-image:  linear-gradient(black 1px, transparent 1px), linear-gradient(90deg, black 1px, transparent 1px); */
    border-radius:1%;
}

.GamePiece
{
    position: absolute;
    text-align: center;
    line-height: 40px; 
    line-height: 36px;
    border-radius:20%;
}

.Player { 
    z-index: 99;    
}

.Ingredient { 
   color:white;
}  

.IngredientReadOnly { 
    background: white; 
 }  

 .PathwayStep
 {
    background: white; 
    opacity: 0.5;
 }

.PathwayTarget
{
    margin: -2px;
    border: 2px dashed white;
} 



@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}